<template>
  <div class="report-detail">
    <template v-if="reportType === 1">
      <past-time-debtors-report />
    </template>
    <template v-else-if="reportType === 2">
      <daily-sales-report />
    </template>
  </div>
</template>

<script>
  import PastTimeDebtorsReport from "./pastTimeDebtorsReport";
  import {getReportDetail} from "../../../../../http/requests/reports";
  import {showLoading} from "../../../../../assets/js/functions";
  import DailySalesReport from "./dailySalesReport";
  export default {
    name: "index",
    components: {DailySalesReport, PastTimeDebtorsReport},
    data () {
      return {
        reportType: 0
      }
    },
    created () {
      this.getReport()
    },
    methods: {
      getReport () {
        getReportDetail(this.$route.params.id).then(response => {
          const report = response.data
          this.reportType = report.report.type
        })
      }
    }
  }
</script>

<style scoped>

</style>
