<template>
  <div class="useral-insert-report">
    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <vs-row>
          <vs-col>
            <div>
              <custom-select label="نوع گزارش"
                             :classes="['w-full']"
                             :default="report.type"
                             :options="reportTypeOptions"
                             v-model="report.type"/>


              <div class="time-picker">
                <div class="date-picker-item">
                  <custom-validate-input label="تاریخ شروع"
                                         :placeholder="'1400/10/18'"
                                         :classes="{'w-full': true}"
                                         :regex="dateRegex"
                                         v-model="report.startRange"/>
                  <date-picker type="date" v-model="report.startRange.value" :max="report.endRange.value"/>
                </div>

                <div class="date-picker-item">
                  <custom-validate-input label="تاریخ پایان"
                                         :placeholder="'1400/11/18'"
                                         :classes="{'w-full': true}"
                                         :regex="dateRegex"
                                         v-model="report.endRange"/>
                  <date-picker type="date" v-model="report.endRange.value" :min="report.startRange.value"/>
                </div>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="insertReportBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
  import CustomDialog from '../../../../../components/customDialog/customDialog'
  import CustomSelect from '../../../../../components/customSelect/customSelect'
  import {insertReport} from "../../../../../http/requests/reports";

  export default {
    name: 'InsertReport',
    components: {
      DatePicker,
      CustomSelect,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      termId: 0
    },
    data() {
      return {
        tabIndex: 0,
        timeRegex: this.$validator('regex.time'),
        dateRegex: this.$validator('regex.date'),
        logs: [],
        reportTypeOptions: [
          {
            label: 'بدهکاران گذشته',
            value: 1
          },
          {
            label: 'فروش روزانه',
            value: 2
          }
        ],
        report: {
          name: {
            value: '',
            isValid: true
          },
          type: {
            label: '-',
            value: 0
          },
          startRange: {
            value: '',
            isValid: false
          },
          endRange: {
            value: '',
            isValid: false
          }
        },
        roles: []
      }
    },
    methods: {
      sendData() {
        if (this.report.type.value === 0) {

          this.$vs.notify({
            title: 'خطا',
            text: 'نوع گزارش انتخاب شده صحیح نمی باشد',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        } else if (!this.report.startRange.isValid) {

          this.$vs.notify({
            title: 'خطا',
            text: 'تاریخ شروع وارد شده صحیح نمی باشد',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        if (!this.report.endRange.isValid) {

          this.$vs.notify({
            title: 'خطا',
            text: 'تاریخ پایان وارد شده صحیح نمی باشد',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const report = {
          type: this.report.type.value,
          start_range: this.report.startRange.value,
          end_range: this.report.endRange.value
        }

        insertReport(report).then(() => {
          this.$vs.notify({
            title: 'پیغام',
            text: 'درخواست گزارش با موفقیت ثبت شد',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')

        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: 'هشدار',
              text: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'type': 'نوع انتخاب شده معتبر نیست!',
              'start_range': 'تاریخ شروع انتخاب شده معتبر نیست!',
              'end_range': 'تاریخ پایان انتخاب شده معتبر نیست!',
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: 'خطا',
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: 'خطا',
                  text: 'افزودن گزارش با خطا مواجه شد',
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-insert-report {
    width: 100%;
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cecece;
      padding: 5px;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }

      .view-box {
        /*min-height: 300px;*/
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .custom-profile-image-input {
          label {
            height: 110px;
            width: 110px;
          }
        }

        .time-picker {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;

          .date-picker-item {
            border: 0 !important;
            border-radius: 0.4rem;
            position: relative;
            flex-grow: 0.5;
            max-width: 48%;

            .custom-input {
              margin: 0;
            }

            .date-picker-label {
              font-size: 12px;
              position: absolute;
              top: -10px;
              left: 5px;
              padding: 0 5px;
              background-color: #ffffff;
              z-index: 1;
            }

            .vpd-input-group {
              width: 20px !important;
              position: absolute;
              top: 6px;
              left: 2px;

              .vpd-icon-btn {
                opacity: 1 !important;
                height: 25px !important;
                width: 20px !important;
                background-color: inherit !important;

                svg {
                  fill: #000000;
                  overflow: visible !important;
                }
              }

              .form-control {
                border: 0;
                width: 100%;
                display: none;
                line-height: 35px;
              }
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .useral-custom-element-select {
          margin: 15px 0;

          .selected {
            height: 35px;
            line-height: 35px;
            font-size: 13px;
          }

          .items {
            line-height: 35px;

            div {
              height: 35px;
              font-size: 13px;
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
